import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/travis/build/emcasa/ui/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Fragment } from 'react';
import View from '@emcasa/ui-dom/components/View';
import Row from '@emcasa/ui-dom/components/Row';
import Col from '@emcasa/ui-dom/components/Col';
import Text from '@emcasa/ui-dom/components/Text';
import Button from '@emcasa/ui-dom/components/Button';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Playground = makeShortcode("Playground");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "view"
    }}>{`View`}</h1>
    <p>{`The `}<inlineCode parentName="p">{`View`}</inlineCode>{` component is a wrapper for an empty and unstyled `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={0} __code={'<View>\n  <View p={4} body>\n    <Text textAlign=\"justify\">\n      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod\n      tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim\n      veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea\n      commodo consequat. Duis aute irure dolor in reprehenderit in voluptate\n      velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint\n      occaecat cupidatat non proident, sunt in culpa qui officia deserunt\n      mollit anim id est laborum.\n    </Text>\n  </View>\n  <View p={4} bottom>\n    <Button active fluid>\n      Fixed at the bottom\n    </Button>\n  </View>\n</View>'} __scope={{
      props,
      DefaultLayout,
      Fragment,
      View,
      Row,
      Col,
      Text,
      Button
    }} mdxType="Playground">
  <View mdxType="View">
    <View p={4} body mdxType="View">
      <Text textAlign="justify" mdxType="Text">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Text>
    </View>
    <View p={4} bottom mdxType="View">
      <Button active fluid mdxType="Button">Fixed at the bottom</Button>
    </View>
  </View>
    </Playground>
    <h2 {...{
      "id": "elevation"
    }}>{`Elevation`}</h2>
    <Playground __position={1} __code={'<Row flexWrap=\"wrap\">\n  {Array(24)\n    .fill(null)\n    .map((_, i) => (\n      <Col key={i} p={5} width={1 / 3}>\n        <View elevation={i} bg=\"lightGrey\" pt={10} pb={10}>\n          <Text size=\"large\" textAlign=\"center\" fontWeight=\"bold\">\n            {i}\n          </Text>\n        </View>\n      </Col>\n    ))}\n</Row>'} __scope={{
      props,
      DefaultLayout,
      Fragment,
      View,
      Row,
      Col,
      Text,
      Button
    }} mdxType="Playground">
  <Row flexWrap="wrap" mdxType="Row">
    {Array(24).fill(null).map((_, i) => <Col key={i} p={5} width={1 / 3} mdxType="Col">
        <View elevation={i} bg="lightGrey" pt={10} pb={10} mdxType="View">
          <Text size="large" textAlign="center" fontWeight="bold" mdxType="Text">
            {i}
          </Text>
        </View>
      </Col>)}
  </Row>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      